// This file was generated by gen-remix.ts at 2022-07-26T21:49:14.074Z

// @remix-run/node@1.6.5
// @remix-run/react@1.6.5
// remix-typedjson@0.0.6

// import overrides
import { useEffect, useState } from "react";
import {
  typedjson,
  redirect,
  useTypedActionData,
  useTypedFetcher,
  useTypedLoaderData,
  useTypedRouteLoaderData,
  typeddefer,
} from "remix-typedjson";

// export packages
export {
  installGlobals,
  createFileSessionStorage,
  unstable_createFileUploadHandler,
  NodeOnDiskFile,
  createCookie,
  createCookieSessionStorage,
  createMemorySessionStorage,
  createSessionStorage,
  createReadableStreamFromReadable,
  readableStreamToString,
  writeAsyncIterableToWritable,
  writeReadableStreamToWritable,
  createRequestHandler,
  createSession,
  isCookie,
  isSession,
  MaxPartSizeExceededError,
  unstable_composeUploadHandlers,
  unstable_createMemoryUploadHandler,
  unstable_parseMultipartFormData,
} from "@remix-run/node";
export type {
  ActionFunctionArgs,
  ActionFunction,
  AppLoadContext,
  Cookie,
  CookieOptions,
  CookieParseOptions,
  CookieSerializeOptions,
  CookieSignatureOptions,
  DataFunctionArgs,
  EntryContext,
  HandleDataRequestFunction,
  HandleDocumentRequestFunction,
  HeadersFunction,
  HtmlLinkDescriptor,
  LinkDescriptor,
  LinksFunction,
  LoaderFunctionArgs,
  LoaderFunction,
  MemoryUploadHandlerFilterArgs,
  MemoryUploadHandlerOptions,
  MetaDescriptor,
  MetaFunction,
  PageLinkDescriptor,
  RequestHandler,
  SerializeFrom,
  ServerBuild,
  ServerEntryModule,
  Session,
  SessionData,
  SessionIdStorageStrategy,
  SessionStorage,
  SignFunction,
  TypedResponse,
  UnsignFunction,
  UploadHandler,
  UploadHandlerPart,
} from "@remix-run/node";
export {
  RemixBrowser,
  Outlet,
  useHref,
  useLocation,
  useNavigate,
  useNavigationType,
  useNavigation,
  useOutlet,
  useOutletContext,
  useParams,
  useResolvedPath,
  useSearchParams,
  Meta,
  Links,
  Scripts,
  Link,
  NavLink,
  Form,
  PrefetchPageLinks,
  LiveReload,
  useFormAction,
  useSubmit,
  useFetchers,
  useBeforeUnload,
  useMatches,
  ScrollRestoration,
  RemixServer,
  isRouteErrorResponse,
  useRouteError,
  useRevalidator,
  matchPath,
  resolvePath,
} from "@remix-run/react";
export type {
  RemixBrowserProps,
  Location,
  NavigateFunction,
  Params,
  Path,
  FetcherWithComponents,
  FormProps,
  SubmitOptions,
  SubmitFunction,
  NavLinkProps,
  LinkProps,
  FormMethod,
  FormEncType,
  ShouldRevalidateFunction,
  RemixServerProps,
  Fetcher,
} from "@remix-run/react";
export {
  deserializeRemix,
  stringifyRemix,
  typedjson,
  typeddefer,
  useTypedActionData,
  useTypedFetcher,
  useTypedLoaderData,
  applyMeta,
  deserialize,
  parse,
  serialize,
  stringify,
} from "remix-typedjson";
export type {
  MetaType,
  TypedJsonResult,
} from "remix-typedjson";

// export overrides
export {
  typedjson as json,
  typeddefer as defer,
  redirect,
  useTypedActionData as useActionData,
  useTypedFetcher as useFetcher,
  useTypedLoaderData as useLoaderData,
  useTypedRouteLoaderData as useRouteLoaderData,
};

export type AppData = Record<string, unknown>

export function forbidden(data: unknown) {
  return typedjson(data, 403)
}

let hydrated = false;
export function useHydrated() {
  const [isHydrated, setIsHydrated] = useState(hydrated);
  useEffect(() => {
    hydrated = true;
    setIsHydrated(hydrated);
  }, [])
  return isHydrated;
}